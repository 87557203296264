<script>
import httpClient from "@/services/http.service";

export default {
  name: "CertificateHoldersConfirmationWSS",
  data(){
    return{
      certificateHoldersData: null,
      certificateHolderId: this.$route.params.id,
    }
  },
  methods:{
    async getCertificateHolderData(certificateHolderId) {
      try {
        const {status, data} = await httpClient.get(`api/certificate-holders/get-certificate-holders?id=${certificateHolderId}`)
        if (status === 200) {
          console.log(data)
          this.certificateHoldersData = data
        }
      } catch (e) {
        this.$error({title: "Ошибка", details: e})
      }
    },
  },
  async mounted() {
    await this.getCertificateHolderData(this.certificateHolderId)
  },
}
</script>

<template>
  <div class="container">
    <div v-if="certificateHoldersData">
      <h1 class="text-center"><strong>{{ certificateHoldersData }}</strong></h1>
    </div>
    <h3 class="text-center my-3">
      Университет Международного Бизнеса имени Кенжегали Сагадиева подтверждает,
      что обладатель данного сертификата успешно завершил учебную программу Зимней
      Научной Школы "UIB WINTER SCIENCE SCHOOL - 2023" объемом 72 часа.
    </h3>
  </div>
</template>

<style scoped>
.container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2{
    margin: auto 0 !important;
  }
}
</style>